import './ContactForm.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Button } from 'react-bootstrap';
import { FiSend } from 'react-icons/fi';
import CustomToast from './CustomToast'

function ContactForm() {
  const [showLoginToast, setShowLoginToast] = useState(false);
  const form = useRef();
  const [sending, setSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '', process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? '', form.current, process.env.REACT_APP_EMAILJS_PUBLIC_ID ?? '')
      .then((result) => {
        const form2 = document.getElementById('form');
        form2.reset();
        setShowLoginToast(true);
        setSending(false);
      }, (error) => {
        //TODO
        setSending(false);
      });
  };

  return (
    <form id='form' className='form' ref={form} onSubmit={sendEmail}>
      <CustomToast delay={5000} setShow={setShowLoginToast} show={showLoginToast} title='Odoslanie E-mailu' small='úspešné' body='Váš e-mail bol úspešne zaslaný na našu adresu. Onedlho sa vám ozveme!' ></CustomToast>
      <label>Celé meno</label>
      <input type="text" name="full_name" required />
      <label>E-mail</label>
      <input type="email" name="user_email" required />
      <label>Správa</label>
      <textarea rows='6' name="message" required />
      <Button disabled={sending} className='buttonForm' type="submit" ><FiSend size={20}></FiSend>{sending ? 'Odosielam...' : 'Odoslať'}</Button>
    </form>
  );
};

export default ContactForm;