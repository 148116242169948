import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import CustomNavbar from './components/CustomNavbar'
import {useState} from 'react'
import LandingPage from './pages/LandingPage'
import ContactPage from './pages/ContactPage'
import ServicesPage from './pages/ServicesPage'
import CennikPage from './pages/CennikPage'
import PediPage from './pages/PediPage'
import TeamPage from './pages/TeamPage'
import Logo from './images/Hands.svg';

function App() {
  const [activePage, setActivePage] = useState('landingPage');
  function handleSetPage(prop:any) {
    if (activePage === prop) {
      return;
    }
    var element = document.getElementById("loadingScreen");
    element?.classList.add("loading");
    setTimeout(() => setActivePage(prop), 500);
    setTimeout(() => element?.classList.remove("loading"), 1000);
  }

  return (
    <div className="App">
      <div id='loadingScreen' className='loadingScreen'><img src={Logo} className="logo-loading" alt="earth-bg" /></div>
      <CustomNavbar setPage={handleSetPage}></CustomNavbar>
      {activePage == 'landingPage' && 
        <LandingPage setPage={handleSetPage}></LandingPage>
      }
      {/* {activePage == 'cennikSection' &&
        <CennikPage></CennikPage>
      } */}
      {activePage == 'servicesSection' &&
        <ServicesPage handleFunction={handleSetPage}></ServicesPage>
      }
      {activePage == 'teamSection' &&
        <TeamPage></TeamPage>
      }
      {activePage == 'contactSection' &&
        <ContactPage></ContactPage>
      }
      {/* {activePage == 'pediSection' &&
        <PediPage></PediPage>
      } */}
    </div>
  );
}

export default App;
