import './ServicesPage.css'
import { GiHealthIncrease, GiTeacher, GiFingernail } from 'react-icons/gi'
import { FaSyringe } from 'react-icons/fa'
import { Button } from 'react-bootstrap';
import Image from '../images/glucomer.svg'
import { CgCopyright } from 'react-icons/cg';

interface ServicePageProps {
    handleFunction: Function
}

function ServicesPage(props: ServicePageProps) {
    return (
        <div className='contentContainer servicesPage'>
            <div className='title'><h1>Naše služby</h1></div>
            <div className='descSection'>
                <div className='description'>V našej ambulancii poskytujeme modernú terapiu diabetu šitú na mieru podľa najnovších odporúčaní.<br/><br/> Pre zaistenie najmodernejšej liečby pre diabetikov 1.typu sme získali <span className='kurziva'>certifikát pre
                        ambulantné nastavovanie dospelého diabetika na liečbu inzulínovou pumpou a CGM systému. </span>
                        <br/><br/>Vzhľadom k naším podiatrickým skúsenostiam dávame aj veľký dôraz na prevenciu vzniku a liečbu
                    diabetickej nohy – v rámci ambulancie máme rozbehnutý projekt medicinálnej pedikúry s odborným
                    dohľadom pod jednou strechou – viac <a href='#pedikura' onClick={() => props.handleFunction('pediSection')}><b>TU</b></a></div>

                <img className='serviceImage' src={Image}></img>
            </div>
            <div className='content'>
                <div className='firstServicesSection serviceSection'>
                    <div className='diagnostika servicesCard'>
                        <GiTeacher size={50} />
                        <h3>Diagnostika</h3>
                        <p>Komplexná diagnostika diabetu mellitu a dyslipidémie
                        </p>
                    </div>
                    <div className='edukacia servicesCard'>
                        <GiTeacher size={50} />
                        <h3>Edukácia</h3>
                        <p>Edukácia pacientov o dietetických opatreniach, komplikáciach cukrovky a ich predchádzaniu<br/><br/>
                        Edukácia diabetikov 1. typu o aplikácií a používaní systému kontinuálneho monitorovania glykémie
                        </p>
                    </div>
                </div>
                <div className='secondServicesSection serviceSection'>
                    <div className='liecba servicesCard'>
                        <GiHealthIncrease size={50} />
                        <h3>Liečba</h3>
                        <p>Komplexná liečba diabetu mellitu 1.typu, 2.typu, gestačného diabetu a iné<br />
                            Liečba diabetickej polyneuropatie
                            podávaním infúznej terapie
                            v samostatnom stacionári
                            mimo ostatných pacientov, zaistenie
                            vhodnej obuvi pre pacientov
                            s polyneuropatiou.<br />
                            Liečba dyslipidémie (poruchy fungovania tukového metabolizmu)
                        </p>
                    </div>
                </div>
                <div className='thirdServicesSection serviceSection'>
                    <div className='odbery servicesCard'>
                        <FaSyringe size={50} />
                        <h3>Odbery</h3>
                        <p>Odbery aj kontroly smerované na presný čas bez nutnosti čakania v čakárni a bez poplatkov</p>
                    </div>
                    <div className='pedikura servicesCard'>
                        <GiFingernail size={50} />
                        <h3>Medicinálna pedikúra</h3>
                        <p>Medicinálna pedikúra vrámci ambulancie</p>
                        <Button onClick={() => props.handleFunction('pediSection')} variant='primary'>Zistiť viac</Button>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <CgCopyright/> All rights reserved DENTAVIA s.r.o.
            </div>
        </div >
    );
}

export default ServicesPage;