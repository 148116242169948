import './CustomToast.css'
import { Toast } from 'react-bootstrap';

interface ToastProps {
    body: string;
    show: boolean;
    setShow: Function;
    title: string;
    small: string;
    delay: number;
}
function CustomToast(props: ToastProps) {
    return (
        <Toast className='toast' onClose={() => props.setShow(false)} show={props.show} delay={props.delay} autohide>
            <Toast.Header>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{props.title}</strong>
                <small>{props.small}</small>
            </Toast.Header>
            <Toast.Body>{props.body}</Toast.Body>
        </Toast>
    )
}

export default CustomToast;