import './Map.css'

function Map() {
    return (
        <div className="mapouter">
            <div className="gmap_canvas">
            <iframe id='gmap_canvas' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2614.656900660673!2d20.306061815858687!3d49.05514689478637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e3b7a55f35531%3A0xce75b712b8c7721e!2sDiabetologick%C3%A1%20ambulancia%20-%20MUDr.%20Viktor%20Hund%C5%BEa!5e0!3m2!1ssk!2ssk!4v1650909990386!5m2!1ssk!2ssk" width="600" height="480" loading="lazy"></iframe>
            </div>
        </div >
    );
}

export default Map;