import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './LinksModal.css';
import { AiOutlineClose } from 'react-icons/ai';

interface ModalProps {
    show: boolean,
    handleClose: Function,
    handleShow: Function,
}

function LinksModal(props: ModalProps) {
    return (
        <>
            <Modal show={props.show} onHide={() => props.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Zaujímavé odkazy</Modal.Title>
                </Modal.Header>
                <Modal.Body>V prípade, že Vás zaujímajú články o diabete na internete odporúčame tieto internetové stránky.<br/>
                    Sú to stránky, ktoré sú overené a vieme, že sa cez nich nedostanete ku dezinformáciám.<br/> Prajeme
                    príjemné čítanie.<br/><br/>
                    <a href ='https://diabetik.sk' target='_blank' rel="noopener noreferrer">https://diabetik.sk</a><br/>
                    <a href ='https://sdia.sk/' target='_blank' rel="noopener noreferrer">https://sdia.sk/</a><br/>
                    <a href ='https://diaslovakia.sk' target='_blank' rel="noopener noreferrer">https://diaslovakia.sk</a><br/>
                    <a href ='https://www.medimerck.sk/sk/home.html' target='_blank' rel="noopener noreferrer">https://www.medimerck.sk/sk/home.html</a><br/>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        <AiOutlineClose size={20}/>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LinksModal;