import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import './FlipCard.css'
import CardModal from './CardModal'

interface FlipCardProps {
    Image: string,
    Name: string,
    Position: string,
    Description: any,
    FullDescription: any
}

function FlipCard(props:FlipCardProps) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <div className="flip-card">
            <CardModal fullDescription={props.FullDescription} title={props.Name} show={show} handleClose={handleClose}></CardModal>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <div className='avatarCard'>
                        <img src={props.Image} className='avatar'></img>
                        <div className='avatarText'><h4>{props.Name}</h4><br /><p>{props.Position}</p></div>
                    </div>
                </div>
                <div className="flip-card-back">
                    <div><h3>{props.Name}</h3><p>{props.Position}</p></div>
                    
                    <div className='cardDescription'>{props.Description}</div>
                    <Button className='cardButton' onClick={handleShow}>Zistiť viac</Button>
                </div>
            </div>
        </div>
    );
}

export default FlipCard;