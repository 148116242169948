import './TeamPage.css'
import Hundza from '../images/ViktorHundza.jpg'
import Barillova from '../images/GabrielaBarillova.jpg'
import Skachova from '../images/MariaSkachova.jpg'
import FlipCard from '../components/FlipCard'
import { CgCopyright } from 'react-icons/cg'
import { IoMdSchool } from 'react-icons/io'
import { Button } from 'react-bootstrap'

function TeamPage() {
    const barillovaDesc = () => (
        <div>
            <h4><IoMdSchool size={50} /></h4>
            <div>
                <h4>Vysoká škola zdrav. a soc. práce sv. Alžbety</h4>
            </div>
            <div>
                Ošetrovateľstvo<br />Bratislava
            </div>
            <hr />
            <div>
                <p>2013-2022 - Inštitút sociálnych vied a zdravotníctva bl. P. P. Gojdiča</p>
                <p>2016 - Ošetrovateľstvo I.stupeň</p>
                <p>2022 - Ošetrovateľstvo II.stupeň</p>
            </div>
        </div>);
    const barillovaFullDesc = () => (
        <div>
            <p>
                <b>2013-2016</b> Inštitút sociálnych vied a zdravotníctva bl. P. P. Gojdiča, Vysoká škola zdrav. a soc. práce
                sv. Alžbety Ošetrovateľstvo I.stupeň<br /><br />
                <b>2019-2022</b> Inštitút sociálnych vied a zdravotníctva bl. P. P. Gojdiča, Vysoká škola zdrav. a soc. práce
                sv. Alžbety Ošetrovateľstvo II.stupeň<br /><br />
                <b>2009-2017</b> Sestra v ambulancii všeobecného lekára Svit<br /><br />
                <b>2017-2018</b> Úseková sestra, Zariadenie pre seniorov a Zariadenie opatrovateľskej služby<br /><br />
                <b>2019-2022</b> Sestra v ambulancii všeobecného lekára Podolínec<br /><br />
                <b>2022</b> Diabetologická ambulancia, Poprad
            </p>
        </div>
    );
    const hundzaDesc = () => (
        <div>
            <h4><IoMdSchool size={50} /></h4>
            <div>
                <h4>Karlova univerzita</h4>
            </div>
            <div>
                1. lekárska fakulta<br />Praha
            </div>
            <hr />
            <div>
                <b>Atestácie</b><br />
                <p>2013 - diabetológia, poruchy látkovej premeny a výživy</p>
                <p>2013 - endokrinológia</p>
            </div>
        </div>);
    const hundzafullDesc = () => (
        <div>
            <p><b>2008</b> ukončené štúdium na 1.lekárskej fakulte na Karlovej univerzite v Prahe<br /><br />
                <b>2008-2015</b> Pardubická krajská nemocnice a.s. diabetologická, endokrinologická a podiatrická
                ambulancia. Od 2013 vedúci lekár osteocentra.<br /><br />
                <b>2013</b> atestácia v odbore diabetológia, poruchy látkovej premeny a výživy<br /><br />
                <b>2013</b> atestácia v odbore endokrinológia<br /><br />
                <b>2015-2020</b> diabetologická ambulancia Nemocnica Poprad a.s.<br /><br />
                <b>2021</b> súkromná diabetologická ambulancia v Poprade (Poliklinika Corrib)<br /><br />
                <b>Od 2018</b> štart unikátneho projektu „Medicinálna pedikúra s lekárskym dohľadom pod jednou
                strechou“ v spolupráci s podiatrickou sestrou Mgr. Škáchovou<br /><br />
                <b>Od 2017</b> odborný poradca pre endokrinológiu a obezitológiu na súkromnej klinike Medicor, Poprad<br /><br />
                <b>02/2022</b> získanie certifikátu pre ambulantné nastavovanie dospelého diabetika na liečbu inzulínovou
                pumpou a CGM systému<br /><br />
                <b>Od 2/2022</b> menovanie do funkcie školiteľa Jesseniovej lekárskej fakulty UK v Martine v odbore
                diabetológia ,poruchy látkovej premeny a výživy<br /><br />
                Pravidelná publikačná a prednášková činnosť v odbore diabetológia, poruchy látkovej premeny a
                výživy</p>
        </div>);
    const skachovaDesc = () => (
        <div>
            <h4><IoMdSchool size={50} /></h4>
            <div>
                <h4>Vysoká škola zdrav. a soc. práce sv. Alžbety</h4>
            </div>
            <div>
                Ošetrovateľstvo<br />Bratislava
            </div>
            <hr />
            <div>
                <p>2008-2011 - Žilinská univerzita v Žiline</p>
                <p>2013 - Certifikovaný kurz pedikér-pedikérka</p>
                <p>2017 - Certifikovaný kurz pre všeobecné sestry podiatrii IKEM Praha</p>
            </div>
        </div>);
    const skachovaFullDesc = () => (
        <div>
            <p>
                <b>2008-2011</b> Žilinská univerzita v Žiline, Fakulta humanitných vied, Katedra pedagogických štúdií<br /><br />
                <b>2011-2013</b> Vysoká škola zdravotníctva a sociálnej práce Sv.Alžbety Bratislava<br /><br />
                <b>2013</b> Akadémia vzdelávania Žilina /Certifikovaný kurz pedikér-pedikérka<br /><br />
                <b>2017</b> Certifikovaný kurz pre všeobecné sestry podiatrii IKEM Praha (akreditovaný kurz MZ ČR)<br /><br />
                <b>1992-2013</b> Liptovská nemocnica s poliklinikou MUDr.I.Stodolu Liptovský Mikuláš - všeobecná sestra<br /><br />
                <b>Od 2014</b> PODia s.r.o /podológ – zúročenie práce a vedomostí v zdravotníctve pri profylaktickom a
                sekundárnom ošetrení nôh diabetikov
            </p>
        </div>
    );
    return (
        <div className='contentContainer aboutPage'>
            <div className='title'><h1>O nás</h1></div>
            <div className='cardsContainer'>
                <div className='cardwrapper'>
                    <div className=''><h3>Lekár</h3></div>
                    <div className='content'>
                        <FlipCard Name='MUDr. Viktor Hundža' Position='diabetológ' Image={Hundza} Description={hundzaDesc()} FullDescription={hundzafullDesc()} />
                    </div>
                </div>
                <div className='cardWrapper'>
                    <div className=''><h3>Sestra</h3></div>
                    <div className='content'>
                        <FlipCard Name='Mgr. Gabriela Barillová' Position='zdravotná sestra' Image={Barillova} Description={barillovaDesc()} FullDescription={barillovaFullDesc()} />
                        {/* <FlipCard Name='Mgr. Mária Škáchová' Position='podiatrická sestra' Image={Skachova} Description={skachovaDesc()} FullDescription={skachovaFullDesc()} /> */}
                    </div>
                </div>
            </div>
            <div className='footer'>
                <CgCopyright /> All rights reserved DENTAVIA s.r.o.
            </div>
        </div>
    );
}

export default TeamPage;