import './LandingPage.css';
import { MdAlternateEmail, MdReadMore } from 'react-icons/md'
import { FaPhoneAlt, FaBuilding } from 'react-icons/fa'
import { Button } from 'react-bootstrap'
import { AiOutlineMessage } from 'react-icons/ai'
import LandingPageImage from '../images/LandingPageImage.svg'
import { ECasenka } from '../icons/ECasenka'
import PokynyModal from '../components/PokynyModal'
import { MdAdsClick } from 'react-icons/md'
import Slideshow from '../components/SlideShow'
import { BsChevronDoubleDown } from 'react-icons/bs'
import vszpLogo from '../images/vszpLogo.jpg'
import doveraLogo from '../images/doveraLogo.jpg'
import unionLogo from '../images/unionLogo.jpg'
import { AiOutlineDown, AiFillStar } from 'react-icons/ai'
import { CgCopyright } from 'react-icons/cg'
import React, { useEffect, useState } from "react";
import { Libraries, useLoadScript } from "@react-google-maps/api";

interface LandingProps {
    setPage: Function
}

const libraries:Libraries = ["places"]; // IMPORTING 'PLACES' LIBRARY FROM GOOGLE MAPS

function LandingPage(props: LandingProps) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isHoursOpen, setHoursOpen] = useState(false);
    const [place, setPlace] = useState<any | undefined>();
	const { isLoaded, loadError } = useLoadScript({
		// TO GET API KEY VISIT: https://developers.google.com/maps/documentation/javascript/get-api-key
		googleMapsApiKey: "AIzaSyBo3pEdtd-JmxKQWco1QhM2kWwlob1v1ZE", // PUT YOUR API KEY OF PROJECT CREATED IN GOOGLE CLOUD CONSOLE
		libraries,
	});

    useEffect(() => {
		if (isLoaded && !loadError) {
			const mapDiv = document.createElement("div"); // CREATING A DIV ELEMENT TO HOST SERVICE
			mapDiv.style.display = "none";
			document.body.appendChild(mapDiv);

			const service = new google.maps.places.PlacesService(mapDiv); // INITIALIZING PLACES SERVICE
			service.getDetails(
				{
					// TO GET PLACE ID VISIT: https://developers.google.com/maps/documentation/places/web-service/place-id
					placeId: "ChIJMVXzVXo7PkcRHnLHuBK3dc4", // THIS IS PLACE ID OF 'TOUR EIFFEL' IN PARIS, FRANCE, PUT YOUR PLACE ID OF THE PLACE TO FETCH REVIEWS
                    language: "sk"
                },

				(place, status) => {
					if (place && status === google.maps.places.PlacesServiceStatus.OK) {
						setPlace(place); // UPDATING STATE WITH REVIEWS RECEIVED
                        console.log(place)
					}
				}
			);

			return () => {
				document.body.removeChild(mapDiv); // CLEANUP BY REMOVING DIV ELEMENT
			};
		}
	}, [isLoaded, loadError]);

    const handleClick = () => {
        setHoursOpen(!isHoursOpen);
    }

    return (
        <div className='landingPageWrapper'>
            <div className='contentContainer landingPage'>
                <BsChevronDoubleDown size={40} className='landingPageScrollDown' />
                <div className='infoSection'>
                    <PokynyModal setPage={props.setPage} show={show} handleClose={handleClose} handleShow={handleShow} />
                    <div className='rating'>
                        <div className='stars'>
                            {place?.rating >= 1 && <AiFillStar size={30}/>}
                            {place?.rating >= 2 && <AiFillStar size={30}/>}
                            {place?.rating >= 3 && <AiFillStar size={30}/>}
                            {place?.rating >= 4 && <AiFillStar size={30}/>}
                            {place?.rating >= 5 && <AiFillStar size={30}/>}
                        </div>
                        {place?.rating}/5 - {place?.user_ratings_total} recenzií
                    </div>
                    <h2>Diabetologická ambulancia</h2>
                    <h1>MUDr. Viktor Hundža</h1>
                    <p><MdAlternateEmail size={30} /> diabetolog.poprad@gmail.com</p>
                    <p><FaPhoneAlt size={30} /> 0910 241 122</p>
                    <p><FaBuilding size={30} /> Poliklinika Corrib, Levočská 26B, 058 01 Poprad</p>
                    <div className='openingHours'>
                        <div className='openHours'><h4>Ordinačné hodiny</h4><a><AiOutlineDown onClick={handleClick} className={isHoursOpen ? 'open arrow' : 'closed arrow'} size={30}/></a></div>
                        {isHoursOpen && 
                        <div className='dayHour'>
                            <div>Po - Pi</div>
                            <div>7:00 - 15:00</div>
                        </div>}
                        { isHoursOpen &&
                        <div className='dayHour'>
                            <div>Odbery</div>
                            <div>7:00 - 10:00</div>
                        </div>}
                        {isHoursOpen &&
                        <div className='dayHour'>
                            <div>Vyšetrovanie </div>
                            <div>8:00 - 14:00</div>
                        </div>
                        }
                        <a onClick={() => handleShow()}><MdAdsClick /> Organizačné pokyny</a>
                    </div>
                    <div className='landingButtons'>
                        <Button variant='secondary' href='#contact' onClick={() => props.setPage('contactSection')}><AiOutlineMessage size={20}/>Kontaktujte nás</Button>
                        <Button href="https://www.ecasenka.sk/ordinacia/mudrviktorhundza/sluzby" target="_blank" rel="noopener noreferrer"><ECasenka size={20} />Žiadosť o recept</Button>
                    </div>

                </div>
                <img src={LandingPageImage} className='landingPageImage' />
            </div>
            {place && place.reviews && place.reviews.length > 0 && <Slideshow reviews={place.reviews}></Slideshow> }
            <div className='partners'>
                <h1 className='title'>Zmluvné poisťovne</h1>
                <div className='partnerLogos'>
                    <img className='partnerLogo vszp' src={vszpLogo} />
                    <img className='partnerLogo dovera' src={doveraLogo} />
                    <img className='partnerLogo union' src={unionLogo} />
                </div>
            </div>
            <div className='footer'>
                <CgCopyright/> All rights reserved DENTAVIA s.r.o.
            </div>
        </div>

    );
}

export default LandingPage;
