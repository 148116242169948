import './ContactPage.css';
import ContactForm from '../components/ContactForm';
import Map from '../components/Map'
import CustomToast from '../components/CustomToast'
import { useState } from 'react';
import { CgCopyright } from 'react-icons/cg';

function ContactPage() {
    return (
        <div className='contentContainer contactPage'>
            
            <div className='title'><h1>Kontaktujte nás</h1></div>
            <div className='content'>
                <div className='formSection'>
                    <ContactForm />
                </div>
                <div className='mapSection'><Map /></div>
            </div>
            <div className='footer'>
                <CgCopyright/> All rights reserved DENTAVIA s.r.o.
            </div>
        </div>
    );
};

export default ContactPage;