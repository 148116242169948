import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './PokynyModal.css';
import { AiOutlineClose } from 'react-icons/ai';

interface ModalProps {
    setPage: Function,
    show: boolean,
    handleClose: Function,
    handleShow: Function,
}

function PokynyModal(props: ModalProps) {
    return (
        <>
            <Modal show={props.show} onHide={() => props.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Organizačné pokyny</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalBody'>Na prvé vyšetrenie v našej ambulancii je potrebné si priniesť výmenný lístok od odosielajúceho lekára
                    a zdravotnú dokumentáciu alebo výpis z karty pacienta.<br/><br/> Na objednanie využite náš <a href='#contact' onClick={() => props.setPage('contactSection')}>kontaktný
                    formulár</a>, alebo sa môžete objednať telefonicky v čase 10-12hod.<br/><br/>

                    Pacienti, u ktorých je indikované vyšetrenie oGTT (po dohode so sestričkou alebo lekárom) stiahnite
                    si pokyny k vyšetreniu <a href='/Pokyny_k_oGTT.pdf' target="_blank" download>TU</a><br/><br/>
                    V našej ambulancii objednávame pacientov na odber krvi aj kontrolu na presný čas bez akýchkoľvek
                    poplatkov.<br/>
                    Recepty si prosím objednávajte cez eČasenku. Snažíme sa týmto redukovať
                    množstvo telefonátov, aby sme sa mohli sústrediť naplno pacientom, ktorí sú práve v ambulancii.
                    Odkaz na eČasenku <a href="https://www.ecasenka.sk/ordinacia/mudrviktorhundza/sluzby" target="_blank" rel="noopener noreferrer">TU</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        <AiOutlineClose size={20} />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PokynyModal;