import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './CardModal.css';
import { AiOutlineClose } from 'react-icons/ai';

interface ModalProps {
    show: boolean,
    handleClose: Function,
    title: string,
    fullDescription: any
}

function CardModal(props: ModalProps) {
    return (
        <>
            <Modal show={props.show} onHide={() => props.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalBody'>
                    {props.fullDescription}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        <AiOutlineClose size={20} />
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CardModal;