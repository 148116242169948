import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './SlideShow.css'
import { AiFillStar } from 'react-icons/ai';
const slideImages = [
  {
    url: '/recenzia1.png',
    caption: 'Slide 1'
  },
  {
    url: '/recenzia2.png',
    caption: 'Slide 2'
  },
  {
    url: '/recenzia3.png',
    caption: 'Slide 3'
  },
  {
    url: '/recenzia4.png',
    caption: 'Slide 2'
  },
  {
    url: '/recenzia5.png',
    caption: 'Slide 3'
  },
  {
    url: '/recenzia6.png',
    caption: 'Slide 2'
  },
];

function Slideshow(reviews: any) {
  console.log(reviews)
  return (
    <div className="slide-container">
      <div><h1 className='title'>Naše referencie</h1></div>
      <Slide duration='5000' transitionDuration='300'>
        {reviews && reviews.reviews && reviews.reviews.map((review: any, index: number) => (
          <div className="each-slide" key={index}>
            <div className='slide-custom'>
              <div className='review' key={review.id || review.time}>
                {review.profile_photo_url && (
                  <img
                    height={50}
                    width={50}
                    src={review.profile_photo_url}
                    alt={`${review.author_name}'s profile`}
                  />
                )}
                <div className='review-content'>
                <p>
                  <strong>{review.author_name}</strong>
                </p>
                <p style={{display: "flex", alignItems: "center", gap: "0.25em"}}>
                  {review.rating}/5 <AiFillStar size={20}/>

                </p>
                <p style={{textAlign: "justify", marginTop: "0.5em"}}>
                  {review.text}
                </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  )
}

export default Slideshow;