import './CustomNavbar.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { AiFillInfoCircle, AiFillHome } from 'react-icons/ai'
import { MdMedicalServices } from 'react-icons/md'
import { RiTeamFill } from 'react-icons/ri'
import {Button} from 'react-bootstrap'
import { AiOutlineMessage } from 'react-icons/ai'
import { ECasenka } from '../icons/ECasenka';
import { useState } from 'react';
import LinksModal from './LinksModal';
import { IoMdPricetags } from 'react-icons/io'
import { GoLink } from 'react-icons/go'

interface NavbarProps {
    setPage: Function
}

function CustomNavbar(props: NavbarProps) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Navbar collapseOnSelect fixed='top' expand="lg" bg="dark" variant="dark">
            <Container>
                <LinksModal handleClose={handleClose} handleShow={handleShow} show={show}/>
                <Navbar.Brand className='brand' onClick={() => props.setPage('landingPage')} href="#home"><b>MUDr. Viktor Hundža</b><br/><span>Diabetologická ambulancia</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={() => props.setPage('landingPage')} href="#home" className='customnavlink'><AiFillHome size={20} />Domov</Nav.Link>
                        <Nav.Link onClick={() => props.setPage('teamSection')} href="#team" className='customnavlink'><AiFillInfoCircle size={20} />O nás</Nav.Link>
                        <Nav.Link onClick={() => props.setPage('servicesSection')} href="#services" className='customnavlink'><MdMedicalServices size={20} />Služby</Nav.Link>
                        {/* <Nav.Link onClick={() => props.setPage('pediSection')} href="#pedikura" className='customnavlink'><RiTeamFill size={20} />Pedikúra</Nav.Link> */}
                        {/* <Nav.Link onClick={() => props.setPage('cennikSection')} href="#cennik" className='customnavlink'><IoMdPricetags size={20} />Cenník</Nav.Link> */}
                        <Nav.Link onClick={handleShow} href="#modalOdkazy" className='customnavlink'><GoLink size={20} />Zaujímavé odkazy</Nav.Link>
                        <Nav.Link href="https://www.ecasenka.sk/ordinacia/mudrviktorhundza/sluzby" target="_blank" rel="noopener noreferrer" className='customnavlink'><ECasenka size={20} />Ečasenka</Nav.Link>
                        <Nav.Link href="#contact" className='customnavlink accountButton'>
                            <Button variant='primary' onClick={() => props.setPage("contactSection")}>
                                <AiOutlineMessage size={20} />Kontaktujte nás
                            </Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
